*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
	font-size: 18px;
}

html, 
body, 
main {
	height: 100%;
	width: 100%;
	position: relative;
}

body {
	margin: 0;
	--color-text: #000;
	--color-text-alt: #848484;
	--color-title: #d3d3d3;
    --color-bg: #DCDCDC;
    --color-link: #0f0f0f;
	--color-link-hover: #000;
    color: var(--color-text);
    background-color: var(--color-bg);
    --cursor-stroke: #0f0f0f;
    --cursor-fill: none;
    --cursor-stroke-width: 1px;
    --cursor-text: #000;
	font-family: tenon, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	text-decoration: underline;
	color: var(--color-link);
	outline: none;
}

a:hover,
a:focus {
	text-decoration: none;
	color: var(--color-link-hover);
	outline: none;
}

.unbutton {
	background: none;
	border: 0;
	padding: 0;
	margin: 0;
	font: inherit;
	cursor: pointer;
}

.unbutton:focus {
	outline: none;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
	content: '';
	position: fixed;
	z-index: 1000;
}

.js .loading::before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5,0.5,1);
	}
}

.message {
	position: relative;
	z-index: 100;
	background: var(--color-text);
	color: var(--color-bg);
	padding: 0.5rem;
	font-size: 0.75rem;
	position: relative;
}

.no-select {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.frame {
	padding: 1rem 5vw;
	position: relative;
	z-index: 1000;
}

.frame__title {
	font-size: 1rem;
	margin: 0 0 1rem;
	font-weight: normal;
}

.frame__links {
	display: inline;
}

.frame__links a:not(:last-child),
.frame__demos a:not(:last-child) {
	margin-right: 1rem;
}

.frame__demos {
	margin: 1rem 0;
}

.frame__demo--current,
.frame__demo--current:hover {
	color: var(--color-text);
	text-decoration: none;
}

.content {
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: 300px;
	justify-content: center;
	position: relative;
	align-items: center;
}

.grid {
	position: absolute;
	width: 120%;
    height: 120%;
    top: -10%;
    left: -10%;
	display: grid;
	grid-template-columns: repeat(50,2%);
	grid-template-rows: repeat(50,2%);
	perspective: 1000px;
}

.grid--inactive {
	pointer-events: none;
}

.grid__item {
	position: relative;
	will-change: transform;
	grid-area: var(--grid-row) / var(--grid-column) / span 12 / span 5;
}

.grid__item-img {
	position: relative;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: 50% 50%;
	border-radius: 10px;
	will-change: transform;
}

.char {
	will-change: transform;
}

/* Shorthand grid-area: grid-row-start / grid-column-start / grid-row-end / grid-column-end */

.pos-1 {
	--grid-row: 9;
	--grid-column: 6;
}

.pos-2 {
	--grid-row: 1;
	--grid-column: 16;
}

.pos-3 {
	--grid-row: 1;
	--grid-column: 27;
}

.pos-4 {
	--grid-row: 13;
	--grid-column: 11;
}

.pos-5 {
	--grid-row: 17;
	--grid-column: 21;
}

.pos-6 {
	--grid-row: 18;
	--grid-column: 39;
}

.pos-7 {
	--grid-row: 26;
	--grid-column: 8;
}

.pos-8 {
	--grid-row: 33;
	--grid-column: 15;
}

.pos-9 {
	--grid-row: 28;
    --grid-column: 26;
}

.pos-10 {
	--grid-row: 31;
	--grid-column: 33;
}

.pos-11 {
	--grid-row: 3;
	--grid-column: 36;
}

.pos-12 {
	--grid-row: 32;
	--grid-column: 20;
}

.pos-13 {
	--grid-row: 22;
	--grid-column: 2;
}

.pos-14 {
	--grid-row: 9;
	--grid-column: 38;
}

.pos-15 {
	--grid-row: 4;
    --grid-column: 7;
}

.pos-16 {
	--grid-row: 28;
	--grid-column: 36;
}

.content__title {
	font-size: 12vw;
	margin: 0;
	line-height: 1.2;
	position: relative;
	color: var(--color-title);
	text-transform: uppercase;
	pointer-events: none;
}

.content__title-line {
	display: block;
	position: relative;
	overflow: hidden;
	line-height: 1;
}

.content__title-line--1 {
	margin-left: -5vw;
}

.content__title-line--2 {
	margin-right: -5vw;
}

.preview {
	position: relative;
	pointer-events: none;
}

.js .preview {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.preview__item {
	position: relative;
	margin: 0 auto;
	padding: 0 5vw;
	display: grid;
	grid-template-rows: auto auto auto;
	grid-template-columns: 50% 50%;
	grid-template-areas: 
	'preview-back preview-back' 
	'preview-img preview-title' 
	'preview-content preview-content';
}

.js .preview__item {
	perspective: 1000px;
}

.js .preview__item:not(.preview__item--open) {
	position: absolute;
	overflow: hidden;
	height: 0;
	opacity: 0;
}

.js .preview__item--open {
	pointer-events: auto;
}

.preview__item-back {
	grid-area: preview-back;
	justify-self: start;
	font-size: 0.857rem;
	text-transform: uppercase;
	font-weight: 500;
	padding-left: 90px;
	margin-bottom: 1rem;
	background: url(../img/arrow.svg) no-repeat 0% 50%;
}

.preview__item-title {
	grid-area: preview-title;
	font-family: dystopian, sans-serif;
	font-size: 12vw;
	font-size: clamp(1rem,10vw,18vh);
	margin: 0;
	grid-column-start: 1;
	align-self: center;
	padding-left: 1rem;
	position: relative;
	overflow: hidden;
	pointer-events: none;
}

.preview__item-imgwrap {
	width: 100px;
	height: 150px;
	border-radius: 10px;
	overflow: hidden;
	grid-area: preview-img;
	transform-origin: 50% 100%;
}

.preview__item-img {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: 50% 50%;
}

.preview__item-content {
	grid-area: preview-content;
	max-width: calc(1100px - 50vh);
	min-height: 440px;
	line-height: 1.3;
}

.preview__item-meta {
	color: var(--color-text-alt);
	text-transform: uppercase;
	font-size: 0.857rem;
	padding: 1rem 0 0;
}

.preview__item-meta span {
	display: block;
}

.preview__item-info {
	display: block;
	color: var(--color-link);
	margin: 1rem 0;
}

.preview__item-button {
	color: #fff;
	border: 0;
	border-radius: 2rem;
	text-transform: uppercase;
	font: inherit;
	font-size: 0.875rem;
	font-weight: 500;
	background: #000;
	padding: 1rem 2rem;
	display: inline-block;
	cursor: pointer;
	margin-bottom: 1rem;
}

.preview__item-button:hover,
.preview__item-button:active,
.preview__item-button:focus {
	outline: none;
	background: var(--color-link);
}

.cursor {
	display: none;
}

@media screen and (min-width: 53em) {
	html, 
	body, 
	main {
		overflow: hidden;
	}
	.message {
		display: none;
	}
	.frame {
		position: fixed;
		z-index: 100;
		top: 0;
		left: 0;
		display: grid;
		align-content: space-between;
		width: 100%;
		max-width: none;
		height: 100%;
		padding: 1rem 1.5rem;
		grid-gap: 6vw;
		pointer-events: none;
		grid-template-columns: auto 1fr auto;
		grid-template-rows: auto auto auto;
		grid-template-areas: 'title links ...'
							'... ... ...'
							'... ... ...';
	}
	.frame__title-wrap {
		grid-area: title;
		display: flex;
	}
	.frame__title {
		margin: 0;
	}
	.frame__tagline {
		position: relative;
		margin: 0 0 0 1rem;
		padding: 0 0 0 1rem;
		opacity: 0.5;
	}
	.frame__demos {
		margin: 0;
		grid-area: demos;
		justify-self: end;
	}
	.frame__links {
		grid-area: links;
		padding: 0;
	}
	.frame a {
		pointer-events: auto;
	}
	.content {
		height: 100%;
	}
	.preview__item {
		height: 100%;
		width: 80vw;
		grid-template-columns: 47% 53%;
		grid-template-rows: minmax(max(5rem, 18vh), 1fr) auto auto 1fr;
		grid-template-areas: 
		'... ...'
		'preview-back ...' 
		'preview-title preview-title' 
		'preview-img preview-content';
	}
	.preview__item-title {
		justify-self: center;
		padding: 0;
	}
	.preview__item-meta {
		padding: 1rem 0;
	}
	.preview__item-info {
		margin: 1rem 0 3rem;
	}
	.preview__item-imgwrap {
		width: 100%;
		height: 100%;
		grid-row-start: 3;
		border-radius: 10px 10px 0 0;
	}
	.preview__item-content {
		padding: 0 0 0 4rem;
	}
}

@media (any-pointer: fine) {
	.cursor {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		pointer-events: none;
		z-index: 9999;
	}

	.cursor__svg {
		pointer-events: none;
		position: absolute;
		top: 0;
		left: 0;
	}

	.cursor__svg-circle {
		fill: var(--cursor-fill);
		stroke: var(--cursor-stroke);
		stroke-width: var(--cursor-stroke-width);
	}

	.cursor__text {
		position: absolute;
		top: 1.875rem;
		left: 80px;
		font-size: 0.857rem;
		text-transform: uppercase;
		font-weight: 500;
		fill: var(--cursor-text);
	}
}